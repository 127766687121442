<template>
  <div id="permissions-page" class="w-full">
    <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
          :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <permission-form v-if="permissionId" />
    <permission-home v-else />
  </div>
</template>

<script>
export default {
  name: "Permissions",
  metaInfo: {
    title: "Permissions",
  },
  components: {
    permissionForm: () => import("../permissions/components/PermissionForm"),
    permissionHome: () => import("../permissions/components/PermissionHome"),
  },
  data: (vm) => ({
      permissionId: vm.$route.params.permissionId,
      breadcrumbList: [{
        text: 'Permissions',
        disabled: true,
        href: '/permissions',
      }]
  }),
  watch: {
    $route(newVal) {
      this.permissionId = newVal.params.permissionId;
      this.setBreadCrumb();
    }
  },
  created() {
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      if (this.permissionId) {
        this.breadcrumbList[0].disabled = false;
        this.breadcrumbList.push({
          text: `${this.permissionId == 'create' ? 'Create' : 'Update'} Permission`,
          disabled: true,
          href: `/permissions/${this.permissionId}`,
        })
      } else {
        this.breadcrumbList = [{
          text: 'Permissions',
          disabled: true,
          href: '/permissions',
        }]
      }
    }
  }
};
</script>
